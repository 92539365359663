import {Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FilterResultsService} from "../../services/filter-results.service";
import {LanguageUnitsService} from "../../services/language-units.service";
import {fromEvent, Subject} from 'rxjs';
import {distinctUntilChanged, debounceTime, takeUntil} from "rxjs/operators";

@Component({
  selector: 'language-units-view',
  templateUrl: './language-units-view.component.html',
  styleUrls: ['./language-units-view.component.scss'],
  providers: [LanguageUnitsService]
})
export class LanguageUnitsViewComponent implements OnInit, AfterViewInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  @Input() public area: string;
  @Input() public type: string;
  isPhrasesSelected: boolean;
  isVocabularySelected: boolean;

  @ViewChild("oleloInput", {static: true}) oleloInput: ElementRef;
  oleloInputKeyEvent: any;
  @ViewChild("englishInput", {static: true}) englishInput: ElementRef;
  englishInputKeyEvent: any;

  private inputDelay = 150;

  constructor(
    private route: ActivatedRoute,
    private filterResultsService: FilterResultsService
  ) {}

  ngOnInit() {
    this.getArea();
    this.isPhrasesSelected = true;
  }

  ngAfterViewInit() {
    // Detect Olelo Input
    this.oleloInputKeyEvent = fromEvent(this.oleloInput.nativeElement, 'keyup');
    this.oleloInputKeyEvent
      .pipe (debounceTime(this.inputDelay), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe(oleloInputEvent => this.filterOleloText(oleloInputEvent.target.value)
    );

    // Detect English Input
    this.englishInputKeyEvent = fromEvent(this.englishInput.nativeElement, 'keyup');
    this.englishInputKeyEvent
      .pipe (debounceTime(this.inputDelay), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe(englishInputEvent => this.filterEnglishText(englishInputEvent.target.value)
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getArea(): void {
    this.route.params.subscribe(routeParams => {
      this.area = routeParams.area;
    });
  }

  onPhrasesSelect(): void {
    this.isPhrasesSelected = true;
    this.isVocabularySelected = false;
  }

  onVocabularySelect(): void {
    this.isPhrasesSelected = false;
    this.isVocabularySelected = true;
  }

  filterEnglishText(text) {
    this.filterResultsService.updateEnglishFilter(text);
  }

  filterOleloText(text) {
    this.filterResultsService.updateOleloFilter(text);
  }

}
