import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LanguageUnitsViewComponent } from "./areas/language-units-view/language-units-view.component";
import { HomePageComponent } from "./areas/home-page/home-page.component";

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomePageComponent },
  { path: 'language-units-view/:area', component: LanguageUnitsViewComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
