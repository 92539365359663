import {LanguageUnit} from "../domain/language-unit";

export const PHRASES: LanguageUnit[] = [
  {
    "English": "Prepare",
    "ʻŌlelo": "E hoʻomākaukau",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "A name chant for _______",
    "ʻŌlelo": "He inoa no _______",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "How much does this cost?",
    "ʻŌlelo": "ʻEhia (kālā)?",
    "Areas": "Purchases, Food, General",
    "Type": "Phrase"
  },
  {
    "English": "And how much does that cost?",
    "ʻŌlelo": "ʻEhia (kālā) no kēnā?",
    "Areas": "Purchases, Food, General",
    "Type": "Phrase"
  },
  {
    "English": "How much for the ______?",
    "ʻŌlelo": "ʻEhia (kālā) no ka ______?",
    "Areas": "Purchases, Food, General",
    "Type": "Phrase"
  },
  {
    "English": "The price is _____.",
    "ʻŌlelo": "He _____ ke kumukūʻai.",
    "Areas": "Purchases, Food, General",
    "Type": "Phrase"
  },
  {
    "English": "Do you have this in _____?",
    "ʻŌlelo": "He _____ paha kāu?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I only have this in _____.",
    "ʻŌlelo": "He _____ wale nō kaʻu.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Yes.",
    "ʻŌlelo": "ʻAe.",
    "Areas": "Purchases, General, School",
    "Type": "Phrase"
  },
  {
    "English": "No.",
    "ʻŌlelo": "ʻAʻole paha.",
    "Areas": "Purchases, General, School",
    "Type": "Phrase"
  },
  {
    "English": "I don't know.",
    "ʻŌlelo": "ʻAʻole maopopo iaʻu.",
    "Areas": "Purchases, General, School",
    "Type": "Phrase"
  },
  {
    "English": "I'll find out.",
    "ʻŌlelo": "Naʻu e huli.",
    "Areas": "Purchases, General, School",
    "Type": "Phrase"
  },
  {
    "English": "How will you be paying?",
    "ʻŌlelo": "Ma ke ʻano hea ʻoe e uku ai?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "How can I pay?",
    "ʻŌlelo": "Ma ke ʻano hea au e uku ai?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "You can pay via _____.",
    "ʻŌlelo": "Hiki ke uku ma _____.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Would you like a receipt?",
    "ʻŌlelo": "I likiki nāu?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Do you need a bag?",
    "ʻŌlelo": "I ʻeke nāu?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I will buy this.",
    "ʻŌlelo": "E kūʻai ana au i kēia.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "You get a discount for shopping in Hawaiian.",
    "ʻŌlelo": "Ua emi ke kumukūʻai no kou kūʻaihele ʻana ma ka ʻōlelo Hawaiʻi.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Can you put this on hold?",
    "ʻŌlelo": "Hiki paha ke hoʻokoe ʻia kēia noʻu?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Thank you (very much).",
    "ʻŌlelo": "Mahalo (nui loa).",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I will come back.",
    "ʻŌlelo": "He hoʻi mai koe.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I need to get more money.",
    "ʻŌlelo": "He pono i kālā hou aku kaʻu.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I'm going to the bank machine and I'll be right back.",
    "ʻŌlelo": "I ka mīkini unuhi kālā ana au a hoʻi hou mai.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Do you need a smaller or bigger size?",
    "ʻŌlelo": "I helu emi iho a nui aʻe paha nou?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "We sold out.",
    "ʻŌlelo": "Ua pau i ke kūʻai ʻia.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "This is beautiful!",
    "ʻŌlelo": "Nani kēia!",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "This is a deal.",
    "ʻŌlelo": "He makepono nō.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Whoa, this is expensive!",
    "ʻŌlelo": "Hō ka pīpiʻi!",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Sticker shock!",
    "ʻŌlelo": "Pohā ka lae!",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "How's it going?",
    "ʻŌlelo": "Pehea mai nei?",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "Do you need help?",
    "ʻŌlelo": "I kōkua nou?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I'm sorry.",
    "ʻŌlelo": "E kala mai.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "My pleasure.",
    "ʻŌlelo": "Noʻu ka hauʻoli.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "We have a sale going on now.",
    "ʻŌlelo": "He kūʻaiemi e mālama ʻia nei.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "That is beautiful on you.",
    "ʻŌlelo": "Ua nani kēnā iā ʻoe.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I will buy _____.",
    "ʻŌlelo": "E kūʻai ana au he _____.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Until we meet again.",
    "ʻŌlelo": "A hui hou aku nō.",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "Take care.",
    "ʻŌlelo": "E mālama pono.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "... and what would you like?",
    "ʻŌlelo": "... a he aha maila kāu makemake?",
    "Areas": "Purchases, Food, General",
    "Type": "Phrase"
  },
  {
    "English": "... and how much does that cost?",
    "ʻŌlelo": "... a he aha ke kumu uku?",
    "Areas": "Purchases, Food, General",
    "Type": "Phrase"
  },
  {
    "English": "Can I have a _______?",
    "ʻŌlelo": "I _______ naʻu?",
    "Areas": "Purchases, Food, General",
    "Type": "Phrase"
  },
  {
    "English": "Can I have some?",
    "ʻŌlelo": "Naʻu paha kekahi?",
    "Areas": "Purchases, Food, General",
    "Type": "Phrase"
  },
  {
    "English": "Can you buy me a _______?",
    "ʻŌlelo": "E kūʻai paha ʻoe i _____ naʻu?",
    "Areas": "Purchases, Food, General",
    "Type": "Phrase"
  },
  {
    "English": "Come with me!",
    "ʻŌlelo": "E hele kāua/kākou!",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "Do you want to eat?",
    "ʻŌlelo": "E ʻai paha kāua/kākou?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Do you want to share?",
    "ʻŌlelo": "E kaʻana like paha kāua/kākou?",
    "Areas": "General, Food",
    "Type": "Phrase"
  },
  {
    "English": "Sing Again!",
    "ʻŌlelo": "E hīmeni hou!",
    "Areas": "Entertainment",
    "Type": "Phrase"
  },
  {
    "English": "Grab your bag.",
    "ʻŌlelo": "E kiʻi i kāu ʻeke.",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "Grab your jacket/sweater.",
    "ʻŌlelo": "E kiʻi i kou kuka/kueka.",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "Grab your stuff!",
    "ʻŌlelo": "E kiʻi i kāu mau mea.",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "Again!",
    "ʻŌlelo": "Hana hou!",
    "Areas": "Entertainment",
    "Type": "Phrase"
  },
  {
    "English": "How did you like it?",
    "ʻŌlelo": "Pehea aku nei?",
    "Areas": "Food, Entertainment, General",
    "Type": "Phrase"
  },
  {
    "English": "How long until the show?",
    "ʻŌlelo": "ʻEhia ka lōʻihi a hoʻomaka maila ka hōʻikeʻike?",
    "Areas": "Entertainment",
    "Type": "Phrase"
  },
  {
    "English": "How much does this cost?",
    "ʻŌlelo": "ʻEhia (kālā)?",
    "Areas": "Purchases, Food, General",
    "Type": "Phrase"
  },
  {
    "English": "How's the _____?",
    "ʻŌlelo": "Pehea ka _____?",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "I need to go to the bathroom!",
    "ʻŌlelo": "E hele au i ka lua!",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "I want a drink.",
    "ʻŌlelo": "I mea inu naʻu.",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "I want to go home.",
    "ʻŌlelo": "Makemake au e hoʻi i ka hale.",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "I'll wait here.",
    "ʻŌlelo": "E kali au maʻaneʻi.",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "I'll wait over there.",
    "ʻŌlelo": "E kali au ma ʻō.",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "I'm hungry!",
    "ʻŌlelo": "Pōloli au!",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "I'm tired!",
    "ʻŌlelo": "Luhi au!",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "Is there anyone with you?",
    "ʻŌlelo": "Aia paha kekahi me ʻoe?",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "It will be fun!",
    "ʻŌlelo": "E leʻaleʻa ana!",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "Let's eat _______.",
    "ʻŌlelo": "E ʻai kāua/kākou i ka/ke _______.",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Put on your jacket.",
    "ʻŌlelo": "E komo i kou kuka.",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "Put on your shoes.",
    "ʻŌlelo": "E komo i kou kāmaʻa.",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "That was fun!  That was awesome!",
    "ʻŌlelo": "Ua leʻaleʻa akula!",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "The food is burnt!",
    "ʻŌlelo": "Ua pāpaʻa akula ka mea ʻai!",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "The food is delicious!",
    "ʻŌlelo": "ʻOno ka mea ʻai!",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "The food is junk!",
    "ʻŌlelo": "ʻAʻohe ʻono o ka mea ʻai!",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "The food is raw!",
    "ʻŌlelo": "Maka mai ka mea ʻai!",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "The food is sweet!",
    "ʻŌlelo": "Momona ka mea ʻai!",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "What shows are happening?",
    "ʻŌlelo": "ʻO nā hōʻikeʻike hea e hōʻikeʻike mai ana?",
    "Areas": "Entertainment",
    "Type": "Phrase"
  },
  {
    "English": "What talent!",
    "ʻŌlelo": "Hana akula nō!  Kālena hoʻi!",
    "Areas": "Entertainment",
    "Type": "Phrase"
  },
  {
    "English": "When is ___ going to be performing?",
    "ʻŌlelo": "ʻO ka hola ʻehia a______e hōʻikeʻike ana?",
    "Areas": "Entertainment",
    "Type": "Phrase"
  },
  {
    "English": "Where did you get that from?",
    "ʻŌlelo": "No hea mai nei kēnā?",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "Where did you go?",
    "ʻŌlelo": "I hea aku nei ʻoe?",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "Where is _____?",
    "ʻŌlelo": "Aia i hea ʻo______?",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "Where you going?",
    "ʻŌlelo": "I hea ana ʻoe/ʻolua/ʻoukou?",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "Who is performing?",
    "ʻŌlelo": "ʻO wai ka mea e hōʻikeʻike mai ana?",
    "Areas": "Entertainment",
    "Type": "Phrase"
  },
  {
    "English": "Who's over there?",
    "ʻŌlelo": "ʻO wai kēlā?",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "Who's with you?",
    "ʻŌlelo": "ʻO wai kēnā?",
    "Areas": "General",
    "Type": "Phrase"
  },
  {
    "English": "Let's eat!",
    "ʻŌlelo": "E ʻai kāua/kākou!",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Who is the hula teacher?",
    "ʻŌlelo": "ʻO wai ke kumu hula?",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "What is the name of the hālau?",
    "ʻŌlelo": "ʻO wai ka inoa o ka hālau?",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "What type of lei is that?",
    "ʻŌlelo": "Pehea ke ʻano o kēlā/kēnā lei?",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "That is a _______ lei.",
    "ʻŌlelo": "He lei _______ kēlā/kēnā.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "What's wrong with this one?  ",
    "ʻŌlelo": "Ua aha ʻia kēia?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "What's wrong with that one (by you)?",
    "ʻŌlelo": "Ua aha ʻia kēnā?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "What's wrong with that one (away)?",
    "ʻŌlelo": "Ua aha ʻia kēlā?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Might there be a discount should I shop speaking Hawaiian?",
    "ʻŌlelo": "He kūʻaiemi paha ke ʻōlelo Hawaiʻi?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "How many percent discount?",
    "ʻŌlelo": "ʻEhia ka pakeneka kūʻaiemi?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "What happened to the one I was looking at?",
    "ʻŌlelo": "I hea akula kaʻu i nānā mua aku nei?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "This one looks like there is a stain.",
    "ʻŌlelo": "He kohu paha ko kēia mea.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "This one is missing a button.",
    "ʻŌlelo": "Ua lilo paha akula hoʻokahi pihi.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "There is a rip in this one.",
    "ʻŌlelo": "Aia he nahae ma kēia mea.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I'm looking for a _____ lei?",
    "ʻŌlelo": "Huli au i lei ______.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Do you have ______ lei?",
    "ʻŌlelo": "He lei ______ kāu?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Can I buy this one?  ",
    "ʻŌlelo": "Hiki iaʻu ke kūʻai i kēia mea?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Can I buy that one?",
    "ʻŌlelo": "Hiki iaʻu ke kūʻai i kēlā/kēnā mea?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I really like this!",
    "ʻŌlelo": "Makemake nui au i kēia mea.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Will you be here tomorrow?",
    "ʻŌlelo": "Aia ana ʻoe i ʻaneʻi i ka lā ʻapōpō?",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "Do you have a business card?",
    "ʻŌlelo": "He kāleka ʻoihana kāu?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Can I find you on the internet?",
    "ʻŌlelo": "Aia ʻoe ma ka punaewele?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Do you have a website?",
    "ʻŌlelo": "He paena punawele kāu?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Do you ship?  ",
    "ʻŌlelo": "Hiki iā ʻoe ke hoʻouna?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "How do you keep this from crumbling?",
    "ʻŌlelo": "Pehea e mālama i kēia me ka hakihaki ʻole?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Which one is your best seller?",
    "ʻŌlelo": "ʻO ka mea hea ka hoʻolilo nui ʻia?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Can I look at the ________?",
    "ʻŌlelo": "Hiki iaʻu ke nānā i ka/ke ________?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Can I try on the _________?",
    "ʻŌlelo": "Hiki iaʻu ke ʻa'ahu i ka/ke _________?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Can I taste?",
    "ʻŌlelo": "Hiki iaʻu ke hoʻāʻo?",
    "Areas": "Purchases, Food, General",
    "Type": "Phrase"
  },
  {
    "English": "Did you make this?",
    "ʻŌlelo": "Ua hana ʻoe?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Where is it from?",
    "ʻŌlelo": "No hea mai ia mea?",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "Do you do fairs elsewhere?",
    "ʻŌlelo": "Hele nō ʻoe i nā fea ʻē aʻe?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Can I have a future fair schedule?",
    "ʻŌlelo": "He papamanawa fea paha kāu?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "What island are you on?",
    "ʻŌlelo": "Ma ka mokupuni hea ʻoe?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I'm looking for a gift for my __________.",
    "ʻŌlelo": "Ke huli nei au i makana no koʻu/kaʻu _________.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "You look like you are the same size as my _________.",
    "ʻŌlelo": "Kū kou nui i koʻu/kaʻu__________.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "How do I wash this?",
    "ʻŌlelo": "Pehea e holoi ai i kēia?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "You will need to send it to the cleaners.",
    "ʻŌlelo": "E pono ana kou lawe aku i ō ka hale holoi lole.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Does it need ironing?",
    "ʻŌlelo": "Pono ka ʻaiana?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "What is this for?",
    "ʻŌlelo": "I mea aha kēia?",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "That fits you well.",
    "ʻŌlelo": "Kū maikaʻi kēnā iā ʻoe.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "That one is too loose!",
    "ʻŌlelo": "ʻAno ʻaluʻalu kēnā.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "That one is too tight!",
    "ʻŌlelo": "ʻAno likiliki kēnā.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "What's your website address?",
    "ʻŌlelo": "He aha ka helu o kāu paena pūnaewele?",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "What's your email?",
    "ʻŌlelo": "He aha kāu helu leka uila?",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "What's your phone number?",
    "ʻŌlelo": "He aha kāu helu kelepona?",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "I am the artist.",
    "ʻŌlelo": "ʻO au ka hana noʻeau.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I designed this.",
    "ʻŌlelo": "Ua haku au i kēia.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I make these",
    "ʻŌlelo": "Naʻu kēia mau mea i hana.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "I love what I do.",
    "ʻŌlelo": "Aloha nui au i kaʻu hana.",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "Is this your first time to Merrie Monarch?",
    "ʻŌlelo": "ʻO kēia ka makamua o kou hele i ka Mele Monaka?",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "Are you having a wonderful time?",
    "ʻŌlelo": "Maikaʻi paha maila?",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "Please be patient with me, my ʻōlelo Hawaiʻi is not very good.",
    "ʻŌlelo": "E ʻoluʻolu e ahonui mai, ʻaʻole au i mākaukau loa ma ka ʻōlelo Hawaiʻi.",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "Can you ask me again....a little slower?",
    "ʻŌlelo": "E ʻoluʻolu e nīnau hou mai ... e ʻōlelo mālie.",
    "Areas": "Purchases, General",
    "Type": "Phrase"
  },
  {
    "English": "Would you like to be on our mailing list?",
    "ʻŌlelo": "Makemake paha anei 'oe e hoʻokomo i kou inoa ma ka papa inoa leka?",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "We have a free gift for shopping in Hawaiian.",
    "ʻŌlelo": "I makana nāu i kou kūʻaihele ʻana ma ka ʻōlelo Hawaiʻi.",
    "Areas": "Purchases",
    "Type": "Phrase"
  },
  {
    "English": "Where are your utensils?",
    "ʻŌlelo": "Aia i hea nā ʻō me ke puna?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I have a fork?",
    "ʻŌlelo": "I ʻō naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I have a spoon?",
    "ʻŌlelo": "I puna naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I have a knife?",
    "ʻŌlelo": "I pahi naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I have a napkin?",
    "ʻŌlelo": "I kāwele naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I have more napkins?",
    "ʻŌlelo": "I mau kāwele hou naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I have chopsticks?",
    "ʻŌlelo": "I lāʻau ʻai paha naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Where is the trash can?",
    "ʻŌlelo": "Aia i hea ke kini ʻōpala?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "The trash is full?",
    "ʻŌlelo": "Ua piha ke kini ʻōpala?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Where can I get some salt?  ",
    "ʻŌlelo": "Ma hea kahi e kūʻai ai i ka paʻakai?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Where can I get some pepper?",
    "ʻŌlelo": "Ma hea kahi e kūʻai ai i ka pepa?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I have salt?",
    "ʻŌlelo": "I paʻakai paha naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I have pepper?",
    "ʻŌlelo": "I pepa paha naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I have a plate lunch and a soda?",
    "ʻŌlelo": "I pā mea ʻai me ke koloaka naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I have a plate lunch and a bottle water?",
    "ʻŌlelo": "I pā mea ʻai me ka ʻōmole wai naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Let's meet at _______ restaurant for lunch?",
    "ʻŌlelo": "E hui pū kāua/kākou ma ka hale ʻaina ʻo ______ no ka ʻaina awakea?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Let's meet at _______ restaurant for breakfast?",
    "ʻŌlelo": "E hui pū kāua/kākou ma ka hale ʻaina ʻo ______ no ka ʻaina kakahiaka?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Let's meet at _______ restaurant for dinner?",
    "ʻŌlelo": "E hui pū kāua/kākou ma ka hale ʻaina ʻo ______ no ka ʻaina ahiahi?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "What would you like to eat?",
    "ʻŌlelo": "He aha kāu ʻono?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "What would you like to drink?",
    "ʻŌlelo": "He aha kāu inu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "I'd like to drink ______.",
    "ʻŌlelo": "Makemake au e inu i ______.",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "I'd like to eat ________.",
    "ʻŌlelo": "Makemake au e ʻai i ________.",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Do you have Happy Hour?",
    "ʻŌlelo": "He Hola Hape ko ʻoneʻi?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "What time is Happy Hour?",
    "ʻŌlelo": "Hola ʻehia ka Hola Hape?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Here's the menu.",
    "ʻŌlelo": "Eia mai ka papa kuhikuhi mea ʻai.",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I have a menu?",
    "ʻŌlelo": "I papa kuhikuhi mea ʻai naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "What are your drink selections?",
    "ʻŌlelo": "He aha nā koho mea inu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "What are your salad selections?",
    "ʻŌlelo": "He aha nā koho lau ʻai?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "What are your entree selections?",
    "ʻŌlelo": "He aha nā koho ʻai?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "What is your special of the day?",
    "ʻŌlelo": "He aha ka ʻai kūikawā o kēia lā?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "How is it prepared?",
    "ʻŌlelo": "Pehea i hoʻomākaukau ʻia ai?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "What would you recommend?",
    "ʻŌlelo": "He aha kāu e kuhikuhi ai?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I get another drink?",
    "ʻŌlelo": "I inu hou naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I get more water?",
    "ʻŌlelo": "I wai hou naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Where is the restroom?",
    "ʻŌlelo": "Aia i hea ka lua?",
    "Areas": "Food, General",
    "Type": "Phrase"
  },
  {
    "English": "Can I get the check?",
    "ʻŌlelo": "E hō mai i ka pila.",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Table for______.",
    "ʻŌlelo": "I pākaukau no #",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "How many of you?",
    "ʻŌlelo": "ʻEhia ʻoukou?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "You prefer a booth or a table?",
    "ʻŌlelo": "Makemake ʻoe i keʻena a i ʻole i pākaukau?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Do you have crushed peppers?",
    "ʻŌlelo": "He pepa pē kāu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "How do I get to_______?",
    "ʻŌlelo": "Pehea au e hele ai i ______ ?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "The table is wobbly.",
    "ʻŌlelo": "Ua pālokeloke ke pākaukau.",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "The chairs are wobbly.",
    "ʻŌlelo": "Ua pālokeloke nā noho.",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I get more napkins?",
    "ʻŌlelo": "I mau kāwele hou naʻu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "It's chilly in here!",
    "ʻŌlelo": "He huʻihuʻi ʻo loko nei!",
    "Areas": "Food, General",
    "Type": "Phrase"
  },
  {
    "English": "It's too hot in here!",
    "ʻŌlelo": "He wela loa ʻo loko nei!",
    "Areas": "Food, General",
    "Type": "Phrase"
  },
  {
    "English": "Can you turn up the AC?",
    "ʻŌlelo": "Hiki paha iā ʻoe ke hoʻoikaika iki i ke ea huʻihuʻi?",
    "Areas": "Food, General",
    "Type": "Phrase"
  },
  {
    "English": "That smells delicious!",
    "ʻŌlelo": "He ʻono ke honi aku!",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "That looks delicious!",
    "ʻŌlelo": "He ʻono ke nānā aku!",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Can I taste yours?",
    "ʻŌlelo": "He hoʻāʻo paha i kāu?",
    "Areas": "Food",
    "Type": "Phrase"
  },
  {
    "English": "Would you like to try?",
    "ʻŌlelo": "Makemake ʻoe e hoʻāʻo?",
    "Areas": "Food, General",
    "Type": "Phrase"
  },
  {
    "English": "Beautifully executed!",
    "ʻŌlelo": "Hū ka nani!",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "The chanting was awesome!",
    "ʻŌlelo": "Nani maikaʻi ke oli!",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "The chanting sucked!",
    "ʻŌlelo": "ʻAʻohe launa ke oli!",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "The musicians have beautiful voices!",
    "ʻŌlelo": "Hū ka nahenahe o nā hoʻokani!",
    "Areas": "Hula, Entertainment",
    "Type": "Phrase"
  },
  {
    "English": "The musicians sucked!",
    "ʻŌlelo": "ʻAʻohe launa nā kanaka hoʻokani!",
    "Areas": "Hula, Entertainment",
    "Type": "Phrase"
  },
  {
    "English": "The timing is off!",
    "ʻŌlelo": "ʻAʻole i like akula!",
    "Areas": "Hula, Entertainment",
    "Type": "Phrase"
  },
  {
    "English": "That was awesome!",
    "ʻŌlelo": "Keu a ka maikaʻi!",
    "Areas": "Hula, Entertainment",
    "Type": "Phrase"
  },
  {
    "English": "That was the best!",
    "ʻŌlelo": "ʻO ka helu ʻekahi nō ia!",
    "Areas": "Hula, Entertainment",
    "Type": "Phrase"
  },
  {
    "English": "That group topped the other group.",
    "ʻŌlelo": "Ua ʻoi loa aku kā lākou i kā kekahi.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "That is my favorite hālau.",
    "ʻŌlelo": "ʻO kaʻu hālau punahele kēia/kēnā/kēlā.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "That was my least favorite hālau.",
    "ʻŌlelo": "ʻO kēlā hālau ka hope loa o kaʻu punahele.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "That was my favorite dancer.",
    "ʻŌlelo": "ʻO kaʻu ʻōlapa punahele kēlā.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "That was my least favorite dancer.",
    "ʻŌlelo": "ʻO kēlā ʻōlapa ka hope loa o kaʻu punahele.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "I think this is the order (of winners).",
    "ʻŌlelo": "Penei paha ihola nā lanakila.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "Too technical, not enough feeling!",
    "ʻŌlelo": "Maikaʻi ka hoʻopuka e ia naʻe, ʻaʻohe ona ola!",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "I hope they win!",
    "ʻŌlelo": "Manaʻolana au ʻo lākou ka lanakila!",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "I'm rooting for this hālau!",
    "ʻŌlelo": "ʻO kēlā kaʻu punahele e puka!",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "My daughter dances.",
    "ʻŌlelo": "Hula kaʻu kaikamahine.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "My son dances.",
    "ʻŌlelo": "Hula kaʻu keikikāne.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "My boyfriend dances.",
    "ʻŌlelo": "Hula kaʻu ipo.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "My girlfriend dances.",
    "ʻŌlelo": "Hula kaʻu ipo.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "My husband dances.",
    "ʻŌlelo": "Hula kaʻu kāne.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "My wife dances.",
    "ʻŌlelo": "Hula kaʻu wahine.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "The kumu is my sister.",
    "ʻŌlelo": "ʻO ke kumu koʻu kaikuahine/kaikuaʻana/kaikaina.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "The kumu is my brother.",
    "ʻŌlelo": "ʻO ke kumu koʻu kaikunāne/kaikuaʻana/kaikaina.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "I can't wait to see this dance.",
    "ʻŌlelo": "ʻAʻole hiki iaʻu ke kali a hula mai lākou.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "I'm looking forward to the competition.",
    "ʻŌlelo": "Ke pīhoihoi aʻe nei kēia i ka hoʻokūkū.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "How many hālau competing this year?",
    "ʻŌlelo": "ʻEhia mau hālau hula e komo ana i ka hoʻokūkū i kēia makahiki?",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "How many men hālau?",
    "ʻŌlelo": "ʻEhia mau hālau kāne?",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "How many women hālau?",
    "ʻŌlelo": "ʻEhia mau hālau wahine?",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "Are there any hālau coming from the continent?",
    "ʻŌlelo": "He hālau paha i lele maila mai ka ʻāina nui?",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "The leis are gorgeous!",
    "ʻŌlelo": "Ua nani nā lei!",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "The costuming is beautiful!",
    "ʻŌlelo": "Nani nā lole!",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "Something's wrong with the costumes!",
    "ʻŌlelo": "ʻAno nā lole ke nānā aku!",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "They could relax on the makeup.",
    "ʻŌlelo": "ʻAno nui maila ko lākou pena maka.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "They could use more makeup!",
    "ʻŌlelo": "He maikaʻi inā pena maikaʻi maila ko lākou maka.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "They won last year.",
    "ʻŌlelo": "Ua lanakila lākou i kēlā makahiki aku nei.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "They've won for the last several years.",
    "ʻŌlelo": "Ua lanakila lākou i kekahi mau makahiki i hala aku nei.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "They've always placed.",
    "ʻŌlelo": "Lanakila mau lākou.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "So happy that they placed this year.",
    "ʻŌlelo": "Hauʻoli au i ko lākou lanakila i kēia makahiki.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "They always place in kahiko.",
    "ʻŌlelo": "ʻO ka maʻamau lakakila lākou ma ka hula kahiko.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "They always place in ʻauana.",
    "ʻŌlelo": "ʻO ka maʻamau lanakila lākou ma ka hula ʻauana.",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "They're always the top runners for the overall.",
    "ʻŌlelo": "ʻO lākou nā lanakila mau o ka puka lanakila hoʻokahi!",
    "Areas": "Hula",
    "Type": "Phrase"
  },
  {
    "English": "Is your homework finished?",
    "ʻŌlelo": "Ua paʻa ka haʻawina?",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Raise your hand.",
    "ʻŌlelo": "E hāpai i kou lima.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Keep learning!",
    "ʻŌlelo": "E aʻo mau!",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Can I go to the restroom?",
    "ʻŌlelo": "Hiki iaʻu ke hele i ka lumi hoʻopaupilikia (lua)?",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Can I go to the office?",
    "ʻŌlelo": "Hiki iaʻu ke hele i ke keʻena?",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Can I go to the library?",
    "ʻŌlelo": "Hiki iaʻu ke hele i ka hale waihona puke?",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Can I go to the gym?",
    "ʻŌlelo": "Hiki iaʻu ke hele i ka hale haʻuki?",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Can I go to the cafeteria?",
    "ʻŌlelo": "Hiki iaʻu ke hele i ka hale ʻaina?",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Write your name.",
    "ʻŌlelo": "E kākau i kou inoa.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Erase your name.",
    "ʻŌlelo": "E holoi i kou inoa.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Open your book.",
    "ʻŌlelo": "E wehe i kāu puke.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Turn on your computer.",
    "ʻŌlelo": "E ho‘ā i kāu kamepiula.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Turn off your computer.",
    "ʻŌlelo": "E ho‘opio i kāu kamepiula.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Read page #__.",
    "ʻŌlelo": "E heluhelu i ka ‘ao‘ao #__.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Go to page #__.",
    "ʻŌlelo": "E huli i ka ‘ao‘ao #__.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Close your book.",
    "ʻŌlelo": "E pani i kāu puke.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Listen to the question.",
    "ʻŌlelo": "E ho‘olohe mai i ka nīnau.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Respond.",
    "ʻŌlelo": "E pane mai.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Work in groups.",
    "ʻŌlelo": "E hana ma ka hui.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Pair and share.",
    "ʻŌlelo": "E hui pālua.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Help each other.",
    "ʻŌlelo": "E kōkua kekahi i kekahi.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Do your homework.",
    "ʻŌlelo": "E hana i kāu ha‘awina pili home.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Is your homework finished?",
    "ʻŌlelo": "Ua pau kāu ha‘awina pili home?",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Return your homework.",
    "ʻŌlelo": "E ho‘iho‘i mai i kāu ha‘awina pili home.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Go over answers.",
    "ʻŌlelo": "E nānā i nā hā‘ina.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Correct your mistakes.",
    "ʻŌlelo": "E ho‘opololei i nā hemahema.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Take out a piece of paper.",
    "ʻŌlelo": "E unuhi i ka pepa kākau.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Collect the assignment.",
    "ʻŌlelo": "E ‘ohi i nā ha‘awina.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Turn off the lights.",
    "ʻŌlelo": "E ho‘opio i nā kukui.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Turn on the projector.",
    "ʻŌlelo": "E ho‘ā i ka mīkini ho‘olele aka.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Watch the video.",
    "ʻŌlelo": "E nānā i ka wikiō.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Take notes.",
    "ʻŌlelo": "E kakaha.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Take care of your things.",
    "ʻŌlelo": "E mālama i kāu mau mea.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Stand up.",
    "ʻŌlelo": "E kū i luna.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Sit down.",
    "ʻŌlelo": "E noho i lalo.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Get in line.",
    "ʻŌlelo": "E kū ma ka laina.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Hands on the side.",
    "ʻŌlelo": "Lima ma ka ‘ao‘ao.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Sit in a circle.",
    "ʻŌlelo": "E noho lina poepoe.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Sit in rows.",
    "ʻŌlelo": "E noho lālani.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Sit cross-legged.",
    "ʻŌlelo": "E noho ‘aha’aha.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Listen to me.",
    "ʻŌlelo": "E ho‘olohe mai.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Repeat after me.",
    "ʻŌlelo": "E ho‘opili mai.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "With a big voice.",
    "ʻŌlelo": "Me ka leo nui.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "With hushed voices.",
    "ʻŌlelo": "Me ka leo hāwanawana.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "No talking.",
    "ʻŌlelo": "‘A‘ole ‘ōlelo.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Be quiet, class.",
    "ʻŌlelo": "E hāmau e ka papa.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Go to the board (white dry erase).",
    "ʻŌlelo": "E kū ma ka papa ke‘oke‘o.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "It’s lunch time.",
    "ʻŌlelo": "‘O ka wā ‘aina awakea kēia.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "It’s recess (playtime).",
    "ʻŌlelo": "‘O ka wā pā‘ani kēia.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "It’s recess (rest time).",
    "ʻŌlelo": "‘O ka wā ho‘omaha kēia.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "School is pau.",
    "ʻŌlelo": "Ua pau ke kula.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Don’t waste food.",
    "ʻŌlelo": "Mai ho‘opohō mea‘ai.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Yes, and what else?",
    "ʻŌlelo": "‘Ae, a he aha hou a‘e?",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Try. ",
    "ʻŌlelo": "E ho‘ā‘o.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Critique my work. ",
    "ʻŌlelo": "E loiloi i ka‘u hana.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Persist in your work. ",
    "ʻŌlelo": "E noke i kāu hana.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Go on with your education.",
    "ʻŌlelo": "E kaukoe aku ʻoe i ka ʻimi naʻauao.",
    "Areas": "School",
    "Type": "Phrase"
  },
  {
    "English": "Good morning.",
    "ʻŌlelo": "Aloha kakahiaka.",
    "Areas": "County Office, Greetings",
    "Type": "Phrase"
  },
  {
    "English": "Good midday.",
    "ʻŌlelo": "Aloha awakea.",
    "Areas": "County Office, Greetings",
    "Type": "Phrase"
  },
  {
    "English": "Good afternoon.",
    "ʻŌlelo": "Aloha ʻauinalā.",
    "Areas": "County Office, Greetings",
    "Type": "Phrase"
  },
  {
    "English": "Good evening.",
    "ʻŌlelo": "Aloha ahiahi.",
    "Areas": "County Office, Greetings",
    "Type": "Phrase"
  },
  {
    "English": "Have a good day.",
    "ʻŌlelo": "I lā maikaʻi.",
    "Areas": "County Office, General",
    "Type": "Phrase"
  },
  {
    "English": "Take care.",
    "ʻŌlelo": "Mālama pono.",
    "Areas": "County Office, General",
    "Type": "Phrase"
  },
  {
    "English": "Sincerely,",
    "ʻŌlelo": "ʻO ia ihola,",
    "Areas": "County Office",
    "Type": "Phrase"
  },
  {
    "English": "With humility,",
    "ʻŌlelo": "Me ka haʻahaʻa,",
    "Areas": "County Office",
    "Type": "Phrase"
  },
  {
    "English": "Until we meet again.",
    "ʻŌlelo": "A hui hou aku nō.",
    "Areas": "County Office, General",
    "Type": "Phrase"
  },
  {
    "English": "Have a great weekend.",
    "ʻŌlelo": "I hopena pule maikaʻi.",
    "Areas": "County Office, General",
    "Type": "Phrase"
  },
  {
    "English": "How was your weekend?",
    "ʻŌlelo": "Pehea kou hopena pule?",
    "Areas": "County Office, General",
    "Type": "Phrase"
  },
  {
    "English": "How can I help you?",
    "ʻŌlelo": "Pehea au e kōkua iā ʻoe?",
    "Areas": "County Office, General",
    "Type": "Phrase"
  },
  {
    "English": "Thank you for your help.",
    "ʻŌlelo": "Mahalo i kou kōkua.",
    "Areas": "County Office, General",
    "Type": "Phrase"
  },
  {
    "English": "Good!",
    "ʻŌlelo": "Maikaʻi!",
    "Areas": "County Office, Greetings, General",
    "Type": "Phrase"
  },
  {
    "English": "Oh no!",
    "ʻŌlelo": "Auē!",
    "Areas": "County Office, General",
    "Type": "Phrase"
  },
  {
    "English": "What (as in huh?)",
    "ʻŌlelo": "He aha?",
    "Areas": "County Office, General",
    "Type": "Phrase"
  },
  {
    "English": "I am happy.",
    "ʻŌlelo": "Hauʻoli au.",
    "Areas": "County Office, Greetings",
    "Type": "Phrase"
  },
  {
    "English": "I am tired.",
    "ʻŌlelo": "Māluhiluhi au.",
    "Areas": "County Office, Greetings",
    "Type": "Phrase"
  },
  {
    "English": "Greetings. (between you and I / us)",
    "ʻŌlelo": "Aloha kāua.",
    "Areas": "Greetings",
    "Type": "Phrase"
  },
  {
    "English": "Greetings! (between you and I / us)",
    "ʻŌlelo": "Aloha nō kāua.",
    "Areas": "Greetings",
    "Type": "Phrase"
  },
  {
    "English": "How are you?",
    "ʻŌlelo": "Pehea ʻoe?",
    "Areas": "Greetings",
    "Type": "Phrase"
  },
  {
    "English": "How are we (you and I)?",
    "ʻŌlelo": "Pehea kāua?",
    "Areas": "Greetings",
    "Type": "Phrase"
  },
  {
    "English": "How have you been?",
    "ʻŌlelo": "Pehea mai nei ʻoe?",
    "Areas": "Greetings",
    "Type": "Phrase"
  },
  {
    "English": "Good.",
    "ʻŌlelo": "Maikaʻi.",
    "Areas": "Greetings, General",
    "Type": "Phrase"
  },
 {
    "English": "Same as always.",
    "ʻŌlelo": "ʻO ia mau nō.",
    "Areas": "Greetings, General",
    "Type": "Phrase"
  },
 {
    "English": "Where are you from?",
    "ʻŌlelo": "No hea mai ʻoe?",
    "Areas": "Greetings, General",
    "Type": "Phrase"
  },
 {
    "English": "I am from ____.",
    "ʻŌlelo": "No ____ mai au.",
    "Areas": "Greetings, General",
    "Type": "Phrase"
  },
 {
    "English": "I am from Kona.",
    "ʻŌlelo": "No Kona mai au.",
    "Areas": "Greetings",
    "Type": "Phrase"
  },
 {
    "English": "What is your name?",
    "ʻŌlelo": "ʻO wai kou inoa?",
    "Areas": "Greetings, General",
    "Type": "Phrase"
  },
  {
    "English": "My name is ____.",
    "ʻŌlelo": "ʻO ____ koʻu inoa.",
    "Areas": "Greetings, General",
    "Type": "Phrase"
  },
  {
    "English": "My name is Kaleo.",
    "ʻŌlelo": "ʻO Kaleo koʻu inoa.",
    "Areas": "Greetings",
    "Type": "Phrase"
  },
  {
    "English": "Let's play cards.",
    "ʻŌlelo": "E paʻani pepa kuʻu kāua.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "You go first.",
    "ʻŌlelo": "ʻO ʻoe mua.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "I'll go first.",
    "ʻŌlelo": "ʻO au ka mua.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "Do you have any pairs?",
    "ʻŌlelo": "He paʻalua anei kău?",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "I don't have any pairs.",
    "ʻŌlelo": "ʻAʻohe aʻu paʻalua.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "I have a pair of ____.",
    "ʻŌlelo": "He paʻalua o ____.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "I got a pair!",
    "ʻŌlelo": "He paʻalua kaʻu!",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "Do you have a ____?",
    "ʻŌlelo": "He ____ kāu?",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "Yes.",
    "ʻŌlelo": "ʻAe.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "No.",
    "ʻŌlelo": "ʻAʻole.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "Yes, I have a ____.",
    "ʻŌlelo": "ʻAe, he ____ kaʻu.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "I don't have a ____.",
    "ʻŌlelo": "ʻAʻohe aʻu ____.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "Go hunt!",
    "ʻŌlelo": "E hei aʻe!",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "Darn!",
    "ʻŌlelo": "Auē nō kā hoʻi!",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "Thank you.",
    "ʻŌlelo": "Mahalo.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "It's my pleasure.",
    "ʻŌlelo": "Naʻu ka hauʻoli.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "Whose turn is it?",
    "ʻŌlelo": "Ko wai manawa?",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "Go ahead. (You go.)",
    "ʻŌlelo": "E hana ʻoe.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "Your turn.",
    "ʻŌlelo": "Kou manawa.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "It's my turn.",
    "ʻŌlelo": "Koʻu manawa.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "It's his/her/their turn.",
    "ʻŌlelo": "Kona manawa.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "It's ____'s turn.",
    "ʻŌlelo": "Ko ____ manawa.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "I have ____ pairs.",
    "ʻŌlelo": "He paʻalua ____ kaʻu.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "I have # points.",
    "ʻŌlelo": "# aʻu helu ʻai.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "I won.",
    "ʻŌlelo": "Ua lanakila au.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "You won.",
    "ʻŌlelo": "Ua lanakila ʻoe.",
    "Areas": "Games",
    "Type": "Phrase"
  },
  {
    "English": "Good game.",
    "ʻŌlelo": "He kemu maikaʻi.",
    "Areas": "Games",
    "Type": "Phrase"
  },
];
