import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { LanguageUnit } from "../../domain/language-unit";
import { LanguageUnitsService } from "../../services/language-units.service";
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {FilterResultsService} from "../../services/filter-results.service";
import {toNupepa} from "../../nupepafy.js";

@Component({
  selector: 'language-units-list',
  templateUrl: './language-units-list.component.html',
  styleUrls: ['./language-units-list.component.scss']
})
export class LanguageUnitsListComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  @Input() area: string;
  @Input() type: "Phrases" | "Vocabulary";

  AREA_PROP = "Areas";
  list: LanguageUnit[];

  constructor(private languageUnitsService: LanguageUnitsService,
              private route: ActivatedRoute,
              private filterResultsService: FilterResultsService) {
  }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.area = routeParams.area;

      if (this.type === "Vocabulary") {
        this.filterResults("getVocabularyByArea");
      } else if (this.type === "Phrases") {
        this.filterResults("getPhrasesByArea");
      }

    });
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  filterResults(getList: string): void {
    const englishSubscription = this.filterResultsService.englishFilter$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(englishFilterText => {
        // Temporary storage of filtered results
        let filteredResultList: LanguageUnit[];

        // Filter areaFilteredList using the English input text
        this.languageUnitsService[getList](this.area)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(areaFilteredList => {
              filteredResultList = this.performTextFilter(areaFilteredList, "English", englishFilterText);
              const oleloSubscription = this.filterResultsService.oleloFilter$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(oleloFilterText =>
                // Now filter that list using the Olelo input text
                this.list = this.performTextFilter(filteredResultList, "ʻŌlelo", oleloFilterText)
                );
            }
          );
      });
  }

  performTextFilter(langList, language, filterText): LanguageUnit[] {
    if (filterText) {
      langList = langList.filter(
        item => toNupepa(item[language]).toLowerCase()
            .indexOf(toNupepa(filterText).toLowerCase())
          !== -1);
    }
    return langList;
  }

}
