
function replaceAll (input, search, replacement) {
  if (input) {
    let reg = new RegExp(search, "gi");
    return input.replace(reg, replacement);
  }
}

export function toNupepa(words) {
  words = replaceAll(words,"Ā", "A");
  words = replaceAll(words, "Ē", "E");
  words = replaceAll(words,"Ī", "I");
  words = replaceAll(words,"Ō", "O");
  words = replaceAll(words,"Ū", "U");
  words = replaceAll(words,"ā", "a");
  words = replaceAll(words,"ē", "e");
  words = replaceAll(words,"ī", "i");
  words = replaceAll(words,"ō", "o");
  words = replaceAll(words,"ū", "u");
  words = replaceAll(words,"ʻ", "");
  words = replaceAll(words,"'", "");
  words = replaceAll(words,"‘", "");
  return words;
}
