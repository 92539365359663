import {LanguageUnit} from "../domain/language-unit";

export const VOCABULARY: LanguageUnit[] = [
  {
    "English": "Ancient and Traditional Form of Hula",
    "ʻŌlelo": "Hula Kahiko",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "A Contemporary Form of Hula",
    "ʻŌlelo": "Hula ʻAuana",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "expensive",
    "ʻŌlelo": "pīpiʻi",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "cheap",
    "ʻŌlelo": "emi",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "black",
    "ʻŌlelo": "ʻeleʻele",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "blue",
    "ʻŌlelo": "polū",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "brown",
    "ʻŌlelo": "mākuʻe",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "green",
    "ʻŌlelo": "ʻōmaʻomaʻo",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "red",
    "ʻŌlelo": "ʻulaʻula",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "white",
    "ʻŌlelo": "keʻokeʻo",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "variegated",
    "ʻŌlelo": "kalakoa",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "a size small",
    "ʻŌlelo": "helu liʻiliʻi",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "a size medium",
    "ʻŌlelo": "helu lōpū",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "a size large",
    "ʻŌlelo": "helu nui",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "a size X-large",
    "ʻŌlelo": "helu nui keu",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "a size XX-large",
    "ʻŌlelo": "helu nui keu pālua",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "a size XXX-large",
    "ʻŌlelo": "helu nui keu pākolu",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "charge card",
    "ʻŌlelo": "ke kāleka kāki",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "personal check",
    "ʻŌlelo": "ka pila kīkoʻo",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "cash",
    "ʻŌlelo": "ke kālā kūʻike",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Hawaiian bracelet",
    "ʻŌlelo": "ke apo kula Hawaiʻi",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "one",
    "ʻŌlelo": "hoʻokahi",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "two",
    "ʻŌlelo": "ʻelua",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "three",
    "ʻŌlelo": "ʻekolu",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "four",
    "ʻŌlelo": "ʻehā",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "five",
    "ʻŌlelo": "ʻelima",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "six",
    "ʻŌlelo": "ʻeono",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "seven",
    "ʻŌlelo": "ʻehiku",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "eight",
    "ʻŌlelo": "ʻewalu",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "nine",
    "ʻŌlelo": "ʻeiwa",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "ten",
    "ʻŌlelo": "ʻumi",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "one million",
    "ʻŌlelo": "hoʻokahi miliona",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "BBQ plate",
    "ʻŌlelo": "Pā mea ʻai Papekiu",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Burnt",
    "ʻŌlelo": "Pāpaʻa",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Candies",
    "ʻŌlelo": "Kanakē",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Caramel Apples",
    "ʻŌlelo": "ʻĀpala kalamela",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Chicken Plate",
    "ʻŌlelo": "Pā mea ʻai moa",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Chili Dog",
    "ʻŌlelo": "Naʻaukake Kili",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Corn on the Cob",
    "ʻŌlelo": "Kulina",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Cotton Candy",
    "ʻŌlelo": "Kanakē Pulupulu",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Deep Fried Twinkie",
    "ʻŌlelo": "Twinkie palaiʻia",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Delicious",
    "ʻŌlelo": "ʻOno",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "French Fries",
    "ʻŌlelo": "ʻUala palai",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Frozen Lemonade",
    "ʻŌlelo": "Wai lemi paʻahau",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Funnel Cake",
    "ʻŌlelo": "palaoa aweawe ",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Hamburger",
    "ʻŌlelo": "Hamapuka",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Hawaiian Plate",
    "ʻŌlelo": "Pā mea ʻai Hawaiʻi",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Hot Dog",
    "ʻŌlelo": "Naʻaukake",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Ice Cream",
    "ʻŌlelo": "ʻAikalima / Haukalima",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Junk",
    "ʻŌlelo": "ʻAʻohe ʻono",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Lemonade",
    "ʻŌlelo": "Wai lemi",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Malasada",
    "ʻŌlelo": "Palaoa palai Pukikī",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Peanuts",
    "ʻŌlelo": "Pīneki",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Pizza",
    "ʻŌlelo": "Paipika",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Plate lunch",
    "ʻŌlelo": "Pā mea ʻai",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Popcorn",
    "ʻŌlelo": "Kulina pohāpohā",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Pretzels",
    "ʻŌlelo": "Pelena peʻa",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Raw",
    "ʻŌlelo": "Maka",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Roasted Corn",
    "ʻŌlelo": "Kūlina pūlehu ʻia",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Share",
    "ʻŌlelo": "Kaʻanalike",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Shave ice",
    "ʻŌlelo": "Hau kohi",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Snack",
    "ʻŌlelo": "Mea ʻai māmā",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Soda",
    "ʻŌlelo": "Koloaka",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Sweet",
    "ʻŌlelo": "momona",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Teri Burger",
    "ʻŌlelo": "Hamapuka koiū",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Tough",
    "ʻŌlelo": "uaua",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "cash",
    "ʻŌlelo": "kālā māli",
    "Areas": "General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "credit card",
    "ʻŌlelo": "kāleka kāki",
    "Areas": "General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "give",
    "ʻŌlelo": "hāʻawi",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "money",
    "ʻŌlelo": "kālā",
    "Areas": "General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Shoes",
    "ʻŌlelo": "Kāma‘a",
    "Areas": "General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Suit (Clothes)",
    "ʻŌlelo": "Paʻa Lole",
    "Areas": "General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "take",
    "ʻŌlelo": "lawe",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "ticket card",
    "ʻŌlelo": "kāleka likiki",
    "Areas": "General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Dancer",
    "ʻŌlelo": "kanaka hulahula",
    "Areas": "Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Movie",
    "ʻŌlelo": "Ki‘i‘oni‘oni",
    "Areas": "Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Performer",
    "ʻŌlelo": "kanaka hōʻikeʻike",
    "Areas": "Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Singer",
    "ʻŌlelo": "Kanaka hīmeni",
    "Areas": "Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Stage",
    "ʻŌlelo": "ʻĀmui, Kahu",
    "Areas": "Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Video",
    "ʻŌlelo": "Wikiō",
    "Areas": "Entertainment, General, School",
    "Type": "Vocabulary"
  },
  {
    "English": "Song",
    "ʻŌlelo": "Mele",
    "Areas": "Entertainment, General, School",
    "Type": "Vocabulary"
  },
  {
    "English": "Sweet Potato",
    "ʻŌlelo": "ʻuala",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Coffee",
    "ʻŌlelo": "kope",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Milk",
    "ʻŌlelo": "waiū",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Juice",
    "ʻŌlelo": "wai hua ʻai",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "sandwich",
    "ʻŌlelo": "kanauika",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "grilled",
    "ʻŌlelo": "kōʻala",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "gravy",
    "ʻŌlelo": "kai",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "soup",
    "ʻŌlelo": "kupa",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Pancake",
    "ʻŌlelo": "palaoa palai",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Cheese",
    "ʻŌlelo": "waiūpaʻa",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Shrimp",
    "ʻŌlelo": "ʻōpae",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Salad",
    "ʻŌlelo": "lau ʻai",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Portuguese sausage",
    "ʻŌlelo": "naʻaukake Pukikī",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Desert",
    "ʻŌlelo": "mea ʻono",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Appetizer",
    "ʻŌlelo": "pūpū",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Tasteless",
    "ʻŌlelo": "koʻekoʻe",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Crab",
    "ʻŌlelo": "pāpaʻi",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Waffle",
    "ʻŌlelo": "palaoa kīpoʻopoʻo",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Onion",
    "ʻŌlelo": "ʻakaʻakai",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Earrings",
    "ʻŌlelo": "kula pepeiao",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Rings",
    "ʻŌlelo": "komo",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Copper",
    "ʻŌlelo": "keleawe",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Hair Combs",
    "ʻŌlelo": "kahi paʻa lauoho",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Pants",
    "ʻŌlelo": "lole wāwae lōʻihi",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Stickers",
    "ʻŌlelo": "pēpili",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Rocking Chairs",
    "ʻŌlelo": "noho paipai",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Platters",
    "ʻŌlelo": "pā nui",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Pareus",
    "ʻŌlelo": "pareu",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Oil",
    "ʻŌlelo": "ʻaila",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Capes",
    "ʻŌlelo": "kāʻei kua",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Feather Flowers",
    "ʻŌlelo": "pua hulu",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Games",
    "ʻŌlelo": "pāʻani",
    "Areas": "Purchases, General, Entertainment, School",
    "Type": "Vocabulary"
  },
  {
    "English": "Apparel",
    "ʻŌlelo": "lole ",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Kites",
    "ʻŌlelo": "lupe",
    "Areas": "Purchases, Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Taro",
    "ʻŌlelo": "kalo",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Chips",
    "ʻŌlelo": "kipi",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Water",
    "ʻŌlelo": "wai",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Donuts",
    "ʻŌlelo": "mea ʻono koneko",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Bamboo",
    "ʻŌlelo": "ʻohe",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Sewn Items",
    "ʻŌlelo": "mea i humu ʻia",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Soap",
    "ʻŌlelo": "kopa",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Pepper",
    "ʻŌlelo": "pepa",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Mountain Apple",
    "ʻŌlelo": "ʻōhiʻa ʻai",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Butter",
    "ʻŌlelo": "waiū paka",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Slingshot",
    "ʻŌlelo": "maʻa",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Feather Standard",
    "ʻŌlelo": "kāhili",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Cordage",
    "ʻŌlelo": "kaula",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "CDs",
    "ʻŌlelo": "pā sēdē",
    "Areas": "Purchases, Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Necklaces",
    "ʻŌlelo": "lei puʻu",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Pearl",
    "ʻŌlelo": "momi",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Jackets",
    "ʻŌlelo": "kuka, lākeke",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Seed Lei",
    "ʻŌlelo": "lei ʻanoʻano",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Breadfruit",
    "ʻŌlelo": "ʻulu",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Brownies",
    "ʻŌlelo": "palauni",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Pineapple",
    "ʻŌlelo": "hala kahiki",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Cups",
    "ʻŌlelo": "kīʻaha",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Shorts",
    "ʻŌlelo": "lole wāwae pōkole",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Acrylic",
    "ʻŌlelo": "hūkaʻa ʻea",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Handmade",
    "ʻŌlelo": "hana lima",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Caps",
    "ʻŌlelo": "pāpale",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "ʻUkulele",
    "ʻŌlelo": "ʻukulele",
    "Areas": "Hula, Purchases, Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Ti Leaf",
    "ʻŌlelo": "lāʻī",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Chants",
    "ʻŌlelo": "oli",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Drummers",
    "ʻŌlelo": "hoʻopaʻa",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Composers",
    "ʻŌlelo": "haku mele",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Hula Teachers",
    "ʻŌlelo": "kumu hula",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "garland",
    "ʻŌlelo": "lei",
    "Areas": "Hula, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "flower",
    "ʻŌlelo": "pua",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "gourd",
    "ʻŌlelo": "ipu",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "gourd drum with a top section (heke)",
    "ʻŌlelo": "ipu heke",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "drum",
    "ʻŌlelo": "pahu",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "skirt",
    "ʻŌlelo": "pāʻū",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "teacher",
    "ʻŌlelo": "kumu",
    "Areas": "Hula, School",
    "Type": "Vocabulary"
  },
  {
    "English": "chanter",
    "ʻŌlelo": "mea oli",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "musician",
    "ʻŌlelo": "mea hoʻokani pila",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "gourd rattle",
    "ʻŌlelo": "ʻulīʻulī",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "dance school/group",
    "ʻŌlelo": "hālau",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "judge",
    "ʻŌlelo": "luna loiloi",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "chair",
    "ʻŌlelo": "noho",
    "Areas": "Hula, School",
    "Type": "Vocabulary"
  },
  {
    "English": "dancer",
    "ʻŌlelo": "ʻōlapa",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "singer",
    "ʻŌlelo": "puʻukani",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Hand Painted",
    "ʻŌlelo": "pena lima",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Split Bamboo",
    "ʻŌlelo": "ʻohe ʻoki hapa ʻia",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Tote Bags",
    "ʻŌlelo": "ʻeke liʻiliʻi",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Lotion",
    "ʻŌlelo": "ʻaila hamo",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Watermelon",
    "ʻŌlelo": "ipu haole, ipu ʻai waha",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Engraving",
    "ʻŌlelo": "kahakaha",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Canoes",
    "ʻŌlelo": "waʻa",
    "Areas": "General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Bowling",
    "ʻŌlelo": "maika",
    "Areas": "General, Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Brass",
    "ʻŌlelo": "hao kula",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Kīhei",
    "ʻŌlelo": "kīhei",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Blockprint",
    "ʻŌlelo": "palaka",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Table Runners",
    "ʻŌlelo": "kāʻei pākaukau",
    "Areas": "Food, General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Side Tables",
    "ʻŌlelo": "pākaukau kū ʻaoʻao",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Quarter",
    "ʻŌlelo": "hapahā",
    "Areas": "Food, General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Prints",
    "ʻŌlelo": "kiʻi",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Jewelry",
    "ʻŌlelo": "mea kāhiko kino",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Dolls",
    "ʻŌlelo": "pēpē kiʻi / kiʻi pēpē",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Jam",
    "ʻŌlelo": "kele",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Arrows",
    "ʻŌlelo": "nahau",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Quilts",
    "ʻŌlelo": "kuiki",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Fish Nets",
    "ʻŌlelo": "ʻupena",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Scoop Net",
    "ʻŌlelo": "kāeʻe",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Blankets",
    "ʻŌlelo": "kapa moe/kīhei",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Penny",
    "ʻŌlelo": "kēneka",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Cards",
    "ʻŌlelo": "kāleka",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Chairs",
    "ʻŌlelo": "noho",
    "Areas": "Purchases, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Lei",
    "ʻŌlelo": "lei",
    "Areas": "Purchases, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Niʻihau Shells",
    "ʻŌlelo": "lei pūpū Niʻihau",
    "Areas": "Purchases, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Chains",
    "ʻŌlelo": "kaula",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Kīkepa",
    "ʻŌlelo": "kīkepa",
    "Areas": "Purchases, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Flower Garland",
    "ʻŌlelo": "lei pua",
    "Areas": "Purchases, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Coffee Tables",
    "ʻŌlelo": "pākaukau kope",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Dishes",
    "ʻŌlelo": "(ke) pā",
    "Areas": "Food, Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Photographs",
    "ʻŌlelo": "kiʻi",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Bags",
    "ʻŌlelo": "ʻeke",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Feathers",
    "ʻŌlelo": "hulu",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Hats",
    "ʻŌlelo": "pāpale",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Weapons",
    "ʻŌlelo": "pōkā",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Wrestling",
    "ʻŌlelo": "uma",
    "Areas": "General, Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Guitars",
    "ʻŌlelo": "kīkā",
    "Areas": "General, Entertainment, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Stone",
    "ʻŌlelo": "pōhaku",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Cookies",
    "ʻŌlelo": "kuki",
    "Areas": "Purchases, Food, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Acai Bowls",
    "ʻŌlelo": "pola acai",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Polymer",
    "ʻŌlelo": "ʻea",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Drums",
    "ʻŌlelo": "pahu",
    "Areas": "Purchases, Hula, General, Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Art Prints",
    "ʻŌlelo": "kiʻi noʻeau",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Shampoo",
    "ʻŌlelo": "kopa lauoho",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Oranges",
    "ʻŌlelo": "ʻalani",
    "Areas": "Purchases, Food, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Raisins",
    "ʻŌlelo": "hua waina maloʻo",
    "Areas": "Purchases, Food, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Jelly",
    "ʻŌlelo": "kele",
    "Areas": "Purchases, Food, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Bows",
    "ʻŌlelo": "hīpuʻu pewa",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Bookmarks",
    "ʻŌlelo": "māka puke",
    "Areas": "Purchases, General, Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Walking Stick",
    "ʻŌlelo": "koʻokoʻo",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Porcelain",
    "ʻŌlelo": "pālolo pākē",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Pillows",
    "ʻŌlelo": "uluna",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Beds",
    "ʻŌlelo": "pela moe",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Dollars",
    "ʻŌlelo": "kālā",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Nickel",
    "ʻŌlelo": "hapa ʻumi",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Stationery",
    "ʻŌlelo": "pepa kākau noka",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Bracelets",
    "ʻŌlelo": "apo lima",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Gold",
    "ʻŌlelo": "kula",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Hair Clips",
    "ʻŌlelo": "ʻūmiʻi lauoho",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Blouses/Tops",
    "ʻŌlelo": "palaka",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Slippers",
    "ʻŌlelo": "kalipa",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Floral Hair Picks",
    "ʻŌlelo": "pua hoʻopaʻa lauoho",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Urns",
    "ʻŌlelo": "ipu",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Drinking Glasses",
    "ʻŌlelo": "kīʻaha",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Magnets",
    "ʻŌlelo": "mākēneki",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Purses",
    "ʻŌlelo": "ʻeke",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Cloaks",
    "ʻŌlelo": "kuka",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Feather Garland",
    "ʻŌlelo": "lei hulu",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Tools",
    "ʻŌlelo": "mea hana",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Hawn Drills",
    "ʻŌlelo": "nao wili",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Nose Flutes",
    "ʻŌlelo": "ʻohe hano ihu",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Pandanus",
    "ʻŌlelo": "lauhala",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Fish",
    "ʻŌlelo": "iʻa",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Smoked Meat",
    "ʻŌlelo": "ʻiʻo uahi, ʻiʻo mikomiko",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Gourds",
    "ʻŌlelo": "ipu",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Hula Implements",
    "ʻŌlelo": "pono hula",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Clicking Stones",
    "ʻŌlelo": "ʻiliʻili",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Bath Products",
    "ʻŌlelo": "pono lua",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Bubble Bath",
    "ʻŌlelo": "kopa huʻa",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Apples",
    "ʻŌlelo": "ʻāpala",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Cranberries",
    "ʻŌlelo": "ʻōhelo",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Spreads",
    "ʻŌlelo": "hāpala ",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Spears",
    "ʻŌlelo": "ihe",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Needle",
    "ʻŌlelo": "kui",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Books",
    "ʻŌlelo": "puke",
    "Areas": "Purchases, General, Entertainment, School",
    "Type": "Vocabulary"
  },
  {
    "English": "Knee Drums",
    "ʻŌlelo": "pūniu",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Bed Spreads",
    "ʻŌlelo": "uhipela",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Cents",
    "ʻŌlelo": "keneka",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Silk",
    "ʻŌlelo": "kilika",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "scarves",
    "ʻŌlelo": "hīpuʻu",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Silver",
    "ʻŌlelo": "waikālā",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Hair Pins",
    "ʻŌlelo": "ʻūmiʻi lauoho",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Dresses",
    "ʻŌlelo": "holo muʻu",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Shoes",
    "ʻŌlelo": "kāmaʻa",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Woodwork",
    "ʻŌlelo": "kālaina lāʻau",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Bowls",
    "ʻŌlelo": "pola",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Shells",
    "ʻŌlelo": "pūpū",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Paintings",
    "ʻŌlelo": "kiʻi i pena ʻia",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Wallets",
    "ʻŌlelo": "paiki kālā",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Helmets",
    "ʻŌlelo": "paleupoʻo",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Feather Hatbands",
    "ʻŌlelo": "lei hulu pāpale",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Fishhooks",
    "ʻŌlelo": "makau",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Bones",
    "ʻŌlelo": "iwi",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Bull Roarer",
    "ʻŌlelo": "oeoe",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Coconut",
    "ʻŌlelo": "niu",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Tuna",
    "ʻŌlelo": "ʻahi",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Won Ton",
    "ʻŌlelo": "palaoa mokumoku",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Tie Dyed",
    "ʻŌlelo": "pena lū laholio",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Feather gourd",
    "ʻŌlelo": "ipu hulu",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Hula Skirts",
    "ʻŌlelo": "pāʻū hula",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Body Products",
    "ʻŌlelo": "pono kino",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Salt",
    "ʻŌlelo": "paʻakai",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Pears",
    "ʻŌlelo": "pea",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Blueberries",
    "ʻŌlelo": "pīʻai",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Sharktooth",
    "ʻŌlelo": "niho manō",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Knives",
    "ʻŌlelo": "pahi",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Thread",
    "ʻŌlelo": "lopi",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "DVDs",
    "ʻŌlelo": "DVD",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Rubber Stamp",
    "ʻŌlelo": "kāpala laholio",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Bed Sheets",
    "ʻŌlelo": "uhipela ",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Dining Table",
    "ʻŌlelo": "pākaukau pāʻina",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Dime",
    "ʻŌlelo": "kenikeni",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Fimo",
    "ʻŌlelo": "ʻalolo",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Batik",
    "ʻŌlelo": "kapa kūkaenalo",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Cotton",
    "ʻŌlelo": "pulupulu",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Water gourds",
    "ʻŌlelo": "ipu wai",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Carving",
    "ʻŌlelo": "kālai kiʻi",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Blue",
    "ʻŌlelo": "uliuli, polū",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Grey",
    "ʻŌlelo": "ʻāhinahina",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Mountains",
    "ʻŌlelo": "kuahiwi",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Towards Mt",
    "ʻŌlelo": "i/ma uka",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Door",
    "ʻŌlelo": "puka, ʻīpuka",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Dish Towels",
    "ʻŌlelo": "kāwele pā",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Shirts",
    "ʻŌlelo": "pālule, pale ʻili",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Tunics",
    "ʻŌlelo": "palaka",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Poets",
    "ʻŌlelo": "haku mele",
    "Areas": "General, Entertainment, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Stingray",
    "ʻŌlelo": "hīhīmanu",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Stringing",
    "ʻŌlelo": "kui ʻana",
    "Areas": "Hula, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Color",
    "ʻŌlelo": "kala",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Streams",
    "ʻŌlelo": "kahawai",
    "Areas": "Hula, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Turnovers",
    "ʻŌlelo": "mea ʻono",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Pistil",
    "ʻŌlelo": "kukuna",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Shrub",
    "ʻŌlelo": "laʻa lāʻau",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Raspberry",
    "ʻŌlelo": "ʻākala",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Cold",
    "ʻŌlelo": "anuanu",
    "Areas": "Food, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Paint Brush",
    "ʻŌlelo": "hulu pena",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Cow",
    "ʻŌlelo": "pipi",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Pheasant",
    "ʻŌlelo": "kolohala",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Plumeria",
    "ʻŌlelo": "pua melia",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Shellfish",
    "ʻŌlelo": "iʻa iwi",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Cork",
    "ʻŌlelo": "ʻūpīlāʻau",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Dried",
    "ʻŌlelo": "maloʻo",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Arare",
    "ʻŌlelo": "palena Kepanī",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Waffle Dog",
    "ʻŌlelo": "naʻaukake palaoa kīpoʻopoʻo",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Rayon",
    "ʻŌlelo": "lole paheʻe",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Ivory",
    "ʻŌlelo": "palaoa",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Note Cards",
    "ʻŌlelo": "kāleka noka",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Lavender",
    "ʻŌlelo": "poniʻala",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Towards Sea",
    "ʻŌlelo": "i/ma kai",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "House",
    "ʻŌlelo": "hale",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Hand Towels",
    "ʻŌlelo": "kāwele lima",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "T-Shirts",
    "ʻŌlelo": "pālule-T, pale ʻili",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Designs",
    "ʻŌlelo": "lau",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Musicians",
    "ʻŌlelo": "mea hoʻokani",
    "Areas": "Entertainment, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Bamboo Stamps",
    "ʻŌlelo": "ʻohe kāpala",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Wrapping",
    "ʻŌlelo": "pepa wahī makana",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Plant",
    "ʻŌlelo": "mea kanu",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Aquarium",
    "ʻŌlelo": "pahuwaiea",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Scones",
    "ʻŌlelo": "mea ʻono scone",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Stamen",
    "ʻŌlelo": "pōuleule",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Ginger",
    "ʻŌlelo": "ʻawapuhi",
    "Areas": "Purchases, General, Food, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Pina Colada",
    "ʻŌlelo": "inu hala kahiki",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Warm",
    "ʻŌlelo": "mahana, mehana",
    "Areas": "Food, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Canvas",
    "ʻŌlelo": "kapolena",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Pork",
    "ʻŌlelo": "puaʻa",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Jasmine",
    "ʻŌlelo": "pīkake",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Fiber",
    "ʻŌlelo": "maʻawe",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Mollusk",
    "ʻŌlelo": "pūpū",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Ice Cream",
    "ʻŌlelo": "ʻaikalima",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Ice Cake",
    "ʻŌlelo": "hau",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Shoyu",
    "ʻŌlelo": "koiū",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Stew",
    "ʻŌlelo": "kū",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Li Hing Mui",
    "ʻŌlelo": "pauka li hing mui",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Muslin",
    "ʻŌlelo": "leponalo, makalena",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Sew",
    "ʻŌlelo": "humu",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Yellow",
    "ʻŌlelo": "melemele, lenalena",
    "Areas": "General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Plum",
    "ʻŌlelo": "palama, puluma",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Sea",
    "ʻŌlelo": "kai",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Hills",
    "ʻŌlelo": "puʻu",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Roof",
    "ʻŌlelo": "kaupoku",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Wash Cloths",
    "ʻŌlelo": "kāwele holoi",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Tank Tops",
    "ʻŌlelo": "palaʻili kāʻawe, paleʻili kāʻawe",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Designers",
    "ʻŌlelo": "haku lau",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Artists",
    "ʻŌlelo": "mea hana noʻeau",
    "Areas": "Purchases, General, Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Hula Dancers",
    "ʻŌlelo": "mea hula",
    "Areas": "Hula, Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Net",
    "ʻŌlelo": "ʻupena",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Twisting",
    "ʻŌlelo": "hilo",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Town",
    "ʻŌlelo": "kaona",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Pastry",
    "ʻŌlelo": "mea ʻono",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Muffins",
    "ʻŌlelo": "māpine",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Stem",
    "ʻŌlelo": "lālā",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Over ripe",
    "ʻŌlelo": "palahē",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Tumeric",
    "ʻŌlelo": "ʻōlena",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Tea",
    "ʻŌlelo": "kī",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Tapa",
    "ʻŌlelo": "kapa",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Water Color",
    "ʻŌlelo": "pena wai",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Pig",
    "ʻŌlelo": "puaʻa",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Quail",
    "ʻŌlelo": "manu kapalulu",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Pakalana",
    "ʻŌlelo": "pakalana",
    "Areas": "Hula, Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Carver",
    "ʻŌlelo": "kālai",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Smoothies",
    "ʻŌlelo": "inu kāwili",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Taro Leaf",
    "ʻŌlelo": "lūʻau",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Kim Chee",
    "ʻŌlelo": "kim chee",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Pastele",
    "ʻŌlelo": "pastele",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Cereal",
    "ʻŌlelo": "siriala",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Nylon",
    "ʻŌlelo": "lopi",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "License Plates",
    "ʻŌlelo": "pā laikini",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Pink",
    "ʻŌlelo": "ʻākala",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Orange",
    "ʻŌlelo": "ʻalani",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Uplands",
    "ʻŌlelo": "i/ma uka",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Wall",
    "ʻŌlelo": "paia",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Curtains",
    "ʻŌlelo": "pale puka aniani",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Bath Towels",
    "ʻŌlelo": "kāwele ʻauʻau",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Songs",
    "ʻŌlelo": "mele",
    "Areas": "Hula, Entertainment, General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Sitting Hula",
    "ʻŌlelo": "hula noho",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Photographers",
    "ʻŌlelo": "kanaka paʻi kiʻi",
    "Areas": "Purchases, General, Hula, Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Hula Sisters",
    "ʻŌlelo": "tita hula",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Shark",
    "ʻŌlelo": "manō",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Fish Net",
    "ʻŌlelo": "ʻupena kāʻeʻe",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Island",
    "ʻŌlelo": "mokupuni",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Mountain",
    "ʻŌlelo": "kuahiwi",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Cake",
    "ʻŌlelo": "mea ʻono",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Tree",
    "ʻŌlelo": "kumu lāʻau",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Branch",
    "ʻŌlelo": "lālā",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Green/Not ripe",
    "ʻŌlelo": "maka",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Strawberry",
    "ʻŌlelo": "ʻōhelo papa",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Ice",
    "ʻŌlelo": "hau",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Clothing",
    "ʻŌlelo": "lole ",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Jerky",
    "ʻŌlelo": "ʻiʻo maloʻo",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Chicken",
    "ʻŌlelo": "moa",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Orchids",
    "ʻŌlelo": "ʻokika",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Bougainvillea",
    "ʻŌlelo": "pua pepa",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Baked Goods",
    "ʻŌlelo": "mea ʻono i puhi ʻia",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Caramel",
    "ʻŌlelo": "kalamela",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Marlin",
    "ʻŌlelo": "aʻu",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Kettle",
    "ʻŌlelo": "ipuhao wai wela",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Crackers",
    "ʻŌlelo": "palena",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Salad",
    "ʻŌlelo": "saleta",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Crafts",
    "ʻŌlelo": "nā hana noʻeau",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Purple",
    "ʻŌlelo": "poni",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Maroon",
    "ʻŌlelo": "poni ʻulaʻula",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Marine",
    "ʻŌlelo": "malina, kai",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Window",
    "ʻŌlelo": "puka aniani",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Towels",
    "ʻŌlelo": "kāwele",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Linens",
    "ʻŌlelo": "lilina",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Board Shorts",
    "ʻŌlelo": "lole wāwae pōkole ʻauʻau kai",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Singers",
    "ʻŌlelo": "mea hīmeni",
    "Areas": "Hula, Entertainment",
    "Type": "Vocabulary"
  },
  {
    "English": "Standing Hula",
    "ʻŌlelo": "hula kū",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Painters",
    "ʻŌlelo": "mea pena",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Hula Brothers",
    "ʻŌlelo": "palala hula",
    "Areas": "Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Whale",
    "ʻŌlelo": "koholā",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Weaving",
    "ʻŌlelo": "ulana",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "City",
    "ʻŌlelo": "kaona",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "River",
    "ʻŌlelo": "kahawai",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Cupcake",
    "ʻŌlelo": "mea ʻono cupcake",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Flower Bud",
    "ʻŌlelo": "liko pua",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Mango",
    "ʻŌlelo": "manakō",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Blueberry",
    "ʻŌlelo": "pīʻai",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Hot",
    "ʻŌlelo": "wela",
    "Areas": "General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Tapa Beater",
    "ʻŌlelo": "kuku kapa",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Beef",
    "ʻŌlelo": "pipi",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Turkey",
    "ʻŌlelo": "pelehū, pokeokeo",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Stephanosis",
    "ʻŌlelo": "pua male",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Leaves",
    "ʻŌlelo": "lau",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Baskets",
    "ʻŌlelo": "ʻie",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Snacks",
    "ʻŌlelo": "mea ʻai māmā",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Mocha",
    "ʻŌlelo": "kope moka",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Fried",
    "ʻŌlelo": "palai",
    "Areas": "General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Fish Cubes",
    "ʻŌlelo": "poke iʻa",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Mustard",
    "ʻŌlelo": "mākeke",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Ketchup",
    "ʻŌlelo": "kai ʻōhiʻa",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Cream",
    "ʻŌlelo": "kalima",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Hair Picks",
    "ʻŌlelo": "hoʻopaʻa lauoho",
    "Areas": "Purchases, General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Peacocks",
    "ʻŌlelo": "pīkake",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Gummy Bears",
    "ʻŌlelo": "kanakē pea",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Tongan",
    "ʻŌlelo": "kanaka Tonga",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Ceramic",
    "ʻŌlelo": "lāmeka",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Angel",
    "ʻŌlelo": "ʻānela",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Eucalyptus",
    "ʻŌlelo": "palepiwa",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Coughs",
    "ʻŌlelo": "kunu",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Legs",
    "ʻŌlelo": "wāwae",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Lips",
    "ʻŌlelo": "lehelehe",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Heart",
    "ʻŌlelo": "puʻuwai",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Cinnamon",
    "ʻŌlelo": "kinamona",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Rice",
    "ʻŌlelo": "laiki",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Long Rice",
    "ʻŌlelo": "laiki loloa",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Sculpture",
    "ʻŌlelo": "kiʻi",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Candle",
    "ʻŌlelo": "ihoiho",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Puzzles",
    "ʻŌlelo": "nane ʻāpana",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Tahitian",
    "ʻŌlelo": "kanaka Kahiki",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Farmer",
    "ʻŌlelo": "mahi ʻai",
    "Areas": "Food, Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Phone Covers",
    "ʻŌlelo": "pale kelepona",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Banana",
    "ʻŌlelo": "maiʻa",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Clothes",
    "ʻŌlelo": "lole",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Masseur",
    "ʻŌlelo": "kanaka lomilomi",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Masseuse",
    "ʻŌlelo": "wahine lomilomi",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Children’s (size)",
    "ʻŌlelo": "keiki",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Extra Small",
    "ʻŌlelo": "liʻiliʻi iki",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Cost",
    "ʻŌlelo": "kumu kūʻai",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Rugs",
    "ʻŌlelo": "moena",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Applications",
    "ʻŌlelo": "palapala noi",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Flash Cards",
    "ʻŌlelo": "kāleka aʻo",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Clocks",
    "ʻŌlelo": "uaki",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Lemons",
    "ʻŌlelo": "lemi",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Mixed Plate",
    "ʻŌlelo": "pā mea ʻai",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Roast",
    "ʻŌlelo": "ʻiʻo i puhi ʻia",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Coconut Pudding",
    "ʻŌlelo": "haupia",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Watercress",
    "ʻŌlelo": "leikō",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Mayonnaise",
    "ʻŌlelo": "meoneki",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Sugar",
    "ʻŌlelo": "kōpaʻa",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Gifts",
    "ʻŌlelo": "makana",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Vase",
    "ʻŌlelo": "ipu pua",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Chinese",
    "ʻŌlelo": "Pākē",
    "Areas": "General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Micronesian",
    "ʻŌlelo": "kanaka Mikolonekia",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Lamp",
    "ʻŌlelo": "kukui",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Furniture",
    "ʻŌlelo": "lako hale",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Acacia",
    "ʻŌlelo": "koa",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Colds",
    "ʻŌlelo": "punia, anu",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Head",
    "ʻŌlelo": "poʻo",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Cheeks",
    "ʻŌlelo": "pāpālina",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Chest",
    "ʻŌlelo": "umauma",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Toast (in drinking)",
    "ʻŌlelo": "kāmau",
    "Areas": "General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Toast (bread)",
    "ʻŌlelo": "palaoa pāpaʻa",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Salmon",
    "ʻŌlelo": "kāmano",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Food",
    "ʻŌlelo": "mea ʻai",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Paper",
    "ʻŌlelo": "pepa",
    "Areas": "Purchases, General, School",
    "Type": "Vocabulary"
  },
  {
    "English": "Stars",
    "ʻŌlelo": "hōkū",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Maori",
    "ʻŌlelo": "Maoli",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Embroidery",
    "ʻŌlelo": "humu ʻōniʻoniʻo",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Calendars",
    "ʻŌlelo": "ʻalemanaka",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Medicine",
    "ʻŌlelo": "lāʻau",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Large",
    "ʻŌlelo": "nui",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Double X",
    "ʻŌlelo": "nui pālua",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Expense",
    "ʻŌlelo": "kumu kūʻai",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Liquid",
    "ʻŌlelo": "wai",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Dry Fit",
    "ʻŌlelo": "paleʻili ʻea",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Water Bottles",
    "ʻŌlelo": "ʻōmole wai",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Li Hing Mui Powder",
    "ʻŌlelo": "pauka li hing mui",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Taro/Coco Pudding",
    "ʻŌlelo": "pukini kalo",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Lettuce",
    "ʻŌlelo": "lekuke, kāpiki ʻai maka",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Chopsticks",
    "ʻŌlelo": "lāʻau ʻai",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Baked",
    "ʻŌlelo": "puhi ʻia",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Items",
    "ʻŌlelo": "nā mea like ʻole",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Bottle Jar",
    "ʻŌlelo": "ʻōmole aniani",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Japanese",
    "ʻŌlelo": "Kepanī",
    "Areas": "Food, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Blood",
    "ʻŌlelo": "koko",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Floor",
    "ʻŌlelo": "papahele",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Lights",
    "ʻŌlelo": "kukui",
    "Areas": "Purchases, General, School",
    "Type": "Vocabulary"
  },
  {
    "English": "Napkins",
    "ʻŌlelo": "kāwele pepa",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Asthma",
    "ʻŌlelo": "hānō",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Hair",
    "ʻŌlelo": "lauoho",
    "Areas": "General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Cancer",
    "ʻŌlelo": "maʻi ʻaʻai",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Hundred",
    "ʻŌlelo": "haneli",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Seaweed",
    "ʻŌlelo": "limu",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Octopus",
    "ʻŌlelo": "heʻe",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Drink",
    "ʻŌlelo": "inu",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Pen",
    "ʻŌlelo": "peni",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Statues",
    "ʻŌlelo": "kiʻi kanaka",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Quantity",
    "ʻŌlelo": "nui",
    "Areas": "Purchases, Food, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Light",
    "ʻŌlelo": "kukui",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Onions",
    "ʻŌlelo": "ʻakaʻakai",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Aprons",
    "ʻŌlelo": "ʻepane",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Massage",
    "ʻŌlelo": "lomi",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Sizes",
    "ʻŌlelo": "nui",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Medium",
    "ʻŌlelo": "waena",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Triple X",
    "ʻŌlelo": "nui pākolu",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Sell",
    "ʻŌlelo": "kūʻai aku",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Hard cover",
    "ʻŌlelo": "pale paʻakikī",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Scrubbers",
    "ʻŌlelo": "ʻūpī holoi",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Hydroflasks",
    "ʻŌlelo": "ipu wai",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Chocolate",
    "ʻŌlelo": "kokoleka",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Spam Musubi",
    "ʻŌlelo": "mukupī",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Broiled",
    "ʻŌlelo": "pūlehu",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Tomatoes",
    "ʻŌlelo": "ʻōhiʻa",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Forks",
    "ʻŌlelo": "ʻō",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Per",
    "ʻŌlelo": "pā",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Picture Frame",
    "ʻŌlelo": "mōlina kiʻi",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Pot",
    "ʻŌlelo": "ipuhao",
    "Areas": "Purchases, General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Owl",
    "ʻŌlelo": "pueo",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Sweaters",
    "ʻŌlelo": "kueka",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Stomach",
    "ʻŌlelo": "ʻōpū",
    "Areas": "General, Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Eyes",
    "ʻŌlelo": "maka",
    "Areas": "General, Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Diabetes",
    "ʻŌlelo": "maʻi kōpaʻa",
    "Areas": "Food, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Thousand",
    "ʻŌlelo": "kaukani",
    "Areas": "General, Hula, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Kukui Relish",
    "ʻŌlelo": "ʻinamona",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Squid",
    "ʻŌlelo": "heʻe",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Dessert",
    "ʻŌlelo": "kenakena, monamona",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Pencil",
    "ʻŌlelo": "penikala",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Boxes",
    "ʻŌlelo": "pahu",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Desk",
    "ʻŌlelo": "pākaukau hana",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Bowling Stones",
    "ʻŌlelo": "ʻulu maika",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Loin Cloth",
    "ʻŌlelo": "malo",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Men’s",
    "ʻŌlelo": "kāne",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "4 X",
    "ʻŌlelo": "nui pā hā",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Sale",
    "ʻŌlelo": "kūʻai",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Soft Cover",
    "ʻŌlelo": "pale palupalu",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Playing Cards",
    "ʻŌlelo": "pepa kuʻu, pepa pāʻani",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Oven Mitts",
    "ʻŌlelo": "mikilima ʻoma",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Prunes",
    "ʻŌlelo": "puluna",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Trail Mix",
    "ʻŌlelo": "ʻai māmā siriala",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Brown",
    "ʻŌlelo": "mākuʻe, palaunu",
    "Areas": "General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Cucumbers",
    "ʻŌlelo": "kaʻukama",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Spoons",
    "ʻŌlelo": "puna",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Veterans",
    "ʻŌlelo": "koa kahiko",
    "Areas": "General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Birds",
    "ʻŌlelo": "manu",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Glass",
    "ʻŌlelo": "aniani",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Sāmoan",
    "ʻŌlelo": "Kāmoa",
    "Areas": "General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Pan",
    "ʻŌlelo": "pā",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Candy",
    "ʻŌlelo": "kanakē",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Arms",
    "ʻŌlelo": "lima",
    "Areas": "General",
    "Type": "Vocabulary"
  },
  {
    "English": "Mouth",
    "ʻŌlelo": "waha",
    "Areas": "General, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "High Blood Pressure",
    "ʻŌlelo": "koko piʻi",
    "Areas": "General, Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Garlic",
    "ʻŌlelo": "ʻakaʻakai pīlau",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Million",
    "ʻŌlelo": "miliona",
    "Areas": "General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Taro Paste",
    "ʻŌlelo": "poi",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Sea Urchin",
    "ʻŌlelo": "wana",
    "Areas": "Food, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Pudding",
    "ʻŌlelo": "pukini",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Map",
    "ʻŌlelo": "palapala ʻāina",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Fisherman",
    "ʻŌlelo": "lawaiʻa",
    "Areas": "General, Hula",
    "Type": "Vocabulary"
  },
  {
    "English": "Mouse Pad",
    "ʻŌlelo": "kahua kau ʻiole",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Cloth",
    "ʻŌlelo": "ʻāpana",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Women’s",
    "ʻŌlelo": "wahine",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Extra Large",
    "ʻŌlelo": "nui pā hoʻokahi",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "5X",
    "ʻŌlelo": "nui pā lima",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Sold",
    "ʻŌlelo": "lilo",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Pamphlets",
    "ʻŌlelo": "pepelu",
    "Areas": "Purchases, General",
    "Type": "Vocabulary"
  },
  {
    "English": "Dice",
    "ʻŌlelo": "una",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Silk Garlands",
    "ʻŌlelo": "lei silika",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Apricots",
    "ʻŌlelo": "ʻapelekoka",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Loco Moco",
    "ʻŌlelo": "loko moko",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Somen",
    "ʻŌlelo": "nulu aweawe",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Kūlolo",
    "ʻŌlelo": "kūlolo",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Macaroni",
    "ʻŌlelo": "makaloni",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Sprouts",
    "ʻŌlelo": "kupu",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Colored Pencils",
    "ʻŌlelo": "penikala kala",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Markers",
    "ʻŌlelo": "māka",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Note Pads",
    "ʻŌlelo": "pepa kahakaha",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Pockets",
    "ʻŌlelo": "pakeke",
    "Areas": "Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "Sweet",
    "ʻŌlelo": "Momona/kenakena",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Beer",
    "ʻŌlelo": "Pia",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Wine",
    "ʻŌlelo": "Waina",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Alcohol",
    "ʻŌlelo": "lama",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Salty",
    "ʻŌlelo": "liʻu",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Tough/rubbery",
    "ʻŌlelo": "uaua",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Pizza",
    "ʻŌlelo": "pai pika",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Calamari",
    "ʻŌlelo": "mūheʻe palai",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Nachos",
    "ʻŌlelo": "kipi nako",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "French toast",
    "ʻŌlelo": "palaoa palai Palani",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Oatmeal",
    "ʻŌlelo": "ʻokamila",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Bacon",
    "ʻŌlelo": "pekona",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Ham",
    "ʻŌlelo": "hame",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Blood sausage",
    "ʻŌlelo": "naʻaukake koko",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Omelette",
    "ʻŌlelo": "pākā",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Mushroom",
    "ʻŌlelo": "melehune",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Noodles",
    "ʻŌlelo": "nulu",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Teri beef",
    "ʻŌlelo": "pipi koiū",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Teri chicken",
    "ʻŌlelo": "moa koiū",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Char siu chicken",
    "ʻŌlelo": "moa mikomiko ʻulaʻula",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Roast turkey",
    "ʻŌlelo": "pelehū ʻoma",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Spaghetti & meatballs ",
    "ʻŌlelo": "nulu ʻĪkālia me ka pōpō pipi",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Hot chocolate",
    "ʻŌlelo": "kokoleka wela",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "baby back ribs",
    "ʻŌlelo": "iwi puaʻa pūlehu",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "fish & chips",
    "ʻŌlelo": "ka iʻa me ka ʻuala palai",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "fried fish",
    "ʻŌlelo": "iʻa palai",
    "Areas": "Purchases, Food",
    "Type": "Vocabulary"
  },
  {
    "English": "Menu",
    "ʻŌlelo": "papa kuhikuhi mea ʻai",
    "Areas": "Food",
    "Type": "Vocabulary"
  },
  {
    "English": "dress",
    "ʻŌlelo": "holo muʻu",
    "Areas": "Hula, General, Purchases",
    "Type": "Vocabulary"
  },
  {
    "English": "student",
    "ʻŌlelo": "haumāna",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "raise, lift, carry",
    "ʻŌlelo": "hāpai",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "restroom",
    "ʻŌlelo": "lumi hoʻopaupilikia, lua",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "office",
    "ʻŌlelo": "keʻena",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "counselor",
    "ʻŌlelo": "aʻoaʻo",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "library",
    "ʻŌlelo": "hale waihona puke",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "sports",
    "ʻŌlelo": "haʻuki",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "gym",
    "ʻŌlelo": "hale haʻuki",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "cafeteria",
    "ʻŌlelo": "hale ʻaina",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "name",
    "ʻŌlelo": "inoa",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "book",
    "ʻŌlelo": "puke",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "erase",
    "ʻŌlelo": "holoi",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "eraser",
    "ʻŌlelo": "mea holoi",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "open",
    "ʻŌlelo": "wehe",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "turn on",
    "ʻŌlelo": "hoʻā",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "computer",
    "ʻŌlelo": "kamepiula, lolouila",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "read",
    "ʻŌlelo": "heluhelu",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "page",
    "ʻŌlelo": "ʻaoʻao",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "look for",
    "ʻŌlelo": "huli",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "close",
    "ʻŌlelo": "pani",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "listen",
    "ʻŌlelo": "hoʻolohe",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "question",
    "ʻŌlelo": "nīnau",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "respond",
    "ʻŌlelo": "pane",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "work",
    "ʻŌlelo": "hana",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "group",
    "ʻŌlelo": "hui",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "pair",
    "ʻŌlelo": "hui pālua",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "pair, paired",
    "ʻŌlelo": "pālua",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "help",
    "ʻŌlelo": "kōkua",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "homework",
    "ʻŌlelo": "haʻawina (pili home)",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "finished",
    "ʻŌlelo": "pau",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "return",
    "ʻŌlelo": "hoʻihoʻi",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "look at",
    "ʻŌlelo": "nānā",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "answers",
    "ʻŌlelo": "hāʻina",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "correct",
    "ʻŌlelo": "hoʻopololei",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "mistakes",
    "ʻŌlelo": "hemahema",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "take out",
    "ʻŌlelo": "unuhi",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "collect",
    "ʻŌlelo": "ʻohi, ʻohiʻohi",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "turn off",
    "ʻŌlelo": "hoʻopio",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "projector",
    "ʻŌlelo": "mīkini hoʻolele aka",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "notes",
    "ʻŌlelo": "kakaha",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "take care (of)",
    "ʻŌlelo": "mālama",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "stand",
    "ʻŌlelo": "kū",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "sit",
    "ʻŌlelo": "noho",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "line",
    "ʻŌlelo": "laina",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "hands",
    "ʻŌlelo": "lima",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "side",
    "ʻŌlelo": "ʻaoʻao",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "ring, circle",
    "ʻŌlelo": "lina poepoe",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "row, line",
    "ʻŌlelo": "lālani",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "repeat",
    "ʻŌlelo": "hoʻopili",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "loud voice",
    "ʻŌlelo": "leo nui",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "hushed voice",
    "ʻŌlelo": "leo hāwanawana",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "talk, speak",
    "ʻŌlelo": "ʻōlelo",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "class",
    "ʻŌlelo": "papa",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "whiteboard",
    "ʻŌlelo": "papa keʻokeʻo",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "lunch",
    "ʻŌlelo": "ʻaina awakea",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "play",
    "ʻŌlelo": "pāʻani",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "rest",
    "ʻŌlelo": "hoʻomaha",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "school",
    "ʻŌlelo": "kula",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "waste",
    "ʻŌlelo": "hoʻopohō",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "critique",
    "ʻŌlelo": "loiloi",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "persist",
    "ʻŌlelo": "noke",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "continue on, perservere",
    "ʻŌlelo": "kaukoe",
    "Areas": "School",
    "Type": "Vocabulary"
  },
  {
    "English": "happy",
    "ʻŌlelo": "hauʻoli",
    "Areas": "County Office, Greetings, General",
    "Type": "Vocabulary"
  },
  {
    "English": "tired",
    "ʻŌlelo": "māluhiluhi",
    "Areas": "County Office, Greetings, General",
    "Type": "Vocabulary"
  },
  {
    "English": "sad",
    "ʻŌlelo": "kaumaha",
    "Areas": "County Office, Greetings, General",
    "Type": "Vocabulary"
  },
  {
    "English": "mad",
    "ʻŌlelo": "huhū",
    "Areas": "County Office, General",
    "Type": "Vocabulary"
  },
  {
    "English": "confused",
    "ʻŌlelo": "huikau",
    "Areas": "County Office, General",
    "Type": "Vocabulary"
  },
  {
    "English": "excited",
    "ʻŌlelo": "pīhoihoi",
    "Areas": "County Office, Greetings, General",
    "Type": "Vocabulary"
  },
  {
    "English": "ready",
    "ʻŌlelo": "mākaukau",
    "Areas": "County Office, General",
    "Type": "Vocabulary"
  },
  {
    "English": "sick",
    "ʻŌlelo": "ʻōmaʻimaʻi",
    "Areas": "County Office, General",
    "Type": "Vocabulary"
  },
  {
    "English": "strong",
    "ʻŌlelo": "ikaika",
    "Areas": "County Office, General",
    "Type": "Vocabulary"
  },
  {
    "English": "smart",
    "ʻŌlelo": "akamai",
    "Areas": "County Office, General",
    "Type": "Vocabulary"
  },
  {
    "English": "upset",
    "ʻŌlelo": "nāukiuki",
    "Areas": "County Office, General",
    "Type": "Vocabulary"
  },
  {
    "English": "tired",
    "ʻŌlelo": "luhi",
    "Areas": "Greetings, General",
    "Type": "Vocabulary"
  },
  {
    "English": "busy",
    "ʻŌlelo": "paʻahana",
    "Areas": "Greetings, General",
    "Type": "Vocabulary"
  },
  {
    "English": "good",
    "ʻŌlelo": "maikaʻi",
    "Areas": "Greetings, General",
    "Type": "Vocabulary"
  },
  {
    "English": "joyful",
    "ʻŌlelo": "leʻaleʻa",
    "Areas": "Greetings, General",
    "Type": "Vocabulary"
  },
  {
    "English": "lazy",
    "ʻŌlelo": "moloā",
    "Areas": "Greetings, General",
    "Type": "Vocabulary"
  },
  {
    "English": "nervous",
    "ʻŌlelo": "haʻalulu",
    "Areas": "Greetings, General",
    "Type": "Vocabulary"
  },
  {
    "English": "win",
    "ʻŌlelo": "lanakila",
    "Areas": "Games",
    "Type": "Vocabulary"
  },
  {
    "English": "score",
    "ʻŌlelo": "helu ʻai",
    "Areas": "Games",
    "Type": "Vocabulary"
  },
  {
    "English": "play cards",
    "ʻŌlelo": "pāʻani pepa",
    "Areas": "Games",
    "Type": "Vocabulary"
  },
  {
    "English": "turn",
    "ʻŌlelo": "manawa",
    "Areas": "Games",
    "Type": "Vocabulary"
  },
  {
    "English": "pair",
    "ʻŌlelo": "paʻalua",
    "Areas": "Games",
    "Type": "Vocabulary"
  },
  {
    "English": "pāʻani",
    "ʻŌlelo": "game/play",
    "Areas": "Games",
    "Type": "Vocabulary"
  },
];
