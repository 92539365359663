import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FilterResultsService {

  public englishFilter$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public oleloFilter$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  // Observable streams
  // englishFilter$ = this.englishFilter.asObservable();
  // oleloFilter$ = this.oleloFilter.asObservable();

  constructor() { }

  // Broadcast new English filter
  public updateEnglishFilter(filterText: string) {
    this.englishFilter$.next(filterText);
  }

  // Broadcast new Olelo Filter
  public updateOleloFilter(filterText: string) {
    this.oleloFilter$.next(filterText);
  }
}
