import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
})
export class NavHeaderComponent implements OnInit {

  public isCollapsed: boolean = false;
  constructor() {

    // When the user scrolls down 50px from the top of the document, resize the header's font size
    window.addEventListener('scroll', function() {
      navbarScroll();
    });
    function navbarScroll() {

      const headerLogoImg = document.getElementById('logo-button');

      const y = window.pageYOffset;
      if (y > 10) {
        headerLogoImg.classList.add('small');
      } else if (y < 10) {
        headerLogoImg.classList.remove('small');
      }
    }

  }

  ngOnInit() {
  }

  onNavClick() {
  }

}
