import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { PHRASES } from "../data/phrase-data";
import { VOCABULARY } from "../data/vocabulary-data";
import { LanguageUnit } from "../domain/language-unit";

@Injectable({
  providedIn: 'root'
})
export class LanguageUnitsService {
  AREA_PROP = "Areas";

  constructor() { }

  // getPhrases(): Observable<LanguageUnit[]> {
  //   return of(PHRASES);
  // }

  getPhrasesByArea(area): Observable<LanguageUnit[]> {
    return of(
      this.performAreaFilter(PHRASES, area)
    );
  }

  // getVocabulary(): Observable<LanguageUnit[]> {
  //   return of(VOCABULARY);
  // }

  getVocabularyByArea(area): Observable<LanguageUnit[]> {
    return of(
      this.performAreaFilter(VOCABULARY, area)
    );
  }

  performAreaFilter(list, area): LanguageUnit[] {
    return list.filter(item => item[this.AREA_PROP].toLowerCase().indexOf(area.toLowerCase()) !== -1);
  }

}
